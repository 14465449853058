import { ErrorHandler, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { filter } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	private pendingRoute: string;

	constructor(private router: Router) {
		this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((event: any) => {
			this.pendingRoute = event.url;
		});
	}

	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk.*failed|Failed to fetch dynamically imported module/;
		if (chunkFailedMessage.test(error.message)) {
			if (this.pendingRoute) {
				window.location.assign(window.location.origin + this.pendingRoute);
			} else {
				window.location.reload();
			}
		}

		console.error(error);
	}
}
