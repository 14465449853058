import * as moment from 'moment-timezone';

import { RateMatrixLoader } from './';

export class CompanyRateMatrixLoader {

	public id: number;
	public rateMatrixLoaderId: number;
	public companyId: string;
	public isActive: boolean;
	public activateAlerts: boolean;
	public modifiedDate: Date;
	public errors: string;
	public warnings: string;
	public finishedAt: Date;
	public amount: number;
	public emailCriteriaId: string;
	public emailAttachmentName: string;
	public emailAttachmentSheetName: string;
	public url: string;
	public username: string;
	public password: string;
	public clientId: string;
	public clientSecret: string;
	public accessKeyId: string;
	public token: string;
	public promoCode: string;
	public agentId: string;
	public systemId: string;
	public interfaceId: string;
	public paymentUrl: string;
	public rateUrl: string;
	public accessTokenUrl: string;
	public brokerCode: string;
	public encryptionKey: string;
	public encryptionIV: string;

	public loader: RateMatrixLoader;

	constructor(companyRateMatrixLoader?: CompanyRateMatrixLoader) {
		Object.assign(this, companyRateMatrixLoader);

		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY 00:00:00 a')) : this.modifiedDate;
	}
}
