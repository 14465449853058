import { CONSTANTS, NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { AccountUsage } from './account-usage';
import { Commission } from './commission';
import { CommissionEstimate } from './commission-estimate';
import { CommissionPayable } from './commission-payable';
import { CommissionPayablesSchedule } from './commission-payables-schedule';
import { HomeOwner } from './homeowner';
import { LocationUsage } from './location-usage';
import { PendingCommission } from './pending-commission';
import { State } from './state';
import { Utility } from './utility';

export class ContractLocation {

	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public zipCode: string;
	public contractId: string;
	public contractLocationId: string;
	public utilityId: string;
	public zone: string;
	public rateClass: string;
	public accountNumLabel: string;
	public utilityAccountNum: string;
	public utilityAccountNum2: string;
	public utilityNameKey: string;
	public utilityReferenceNum: string;
	public utilityMeterNum: string;
	public priorSupplier: string;
	public personalPropertyLiability: number;
	public personalLiability: number;
	public schedulePersonalProperty: number;
	public deductible: number;
	public annualUsage: number;
	public status: number;
	public dropDate: Date | string;
	public reinstatementDate: Date | string;
	public statusDescription: string;
	public addressLine: string;
	public homeownerId: string;
	public addDate: Date;
	public statusReasonTypeId: number;
	public sortOrder: number;
	public isActive: boolean;
	public deliveryTypeId: number;
	public etfAmount: number;
	public skipStatusNotification: boolean;
	public effectiveDate: Date | string;
	public disconnectionNoticeSendDate: Date | string;
	public rejectionDate: Date | string;

	public attachmentId: string;

	public isBillTypeOpen: boolean;
	public extraAccountNum: string;

	public homeowner: HomeOwner;
	public state: State;
	public utility: Utility;
	public commissions: Commission[];
	public commissionPayableSchedules: CommissionPayablesSchedule[];
	public commissionPayables: CommissionPayable[];
	public pendingCommissions: PendingCommission[];
	public commissionEstimates: CommissionEstimate[];
	public accountUsages: AccountUsage[];

	// used internally
	public selected: boolean;
	public locationUsage: LocationUsage;
	public requestingUsage: boolean;
	public payableSummaryCommmissions: CommissionPayablesSchedule[];
	public removing: boolean;
	public accountUsageOpened: boolean;

	constructor(contractLocation?: ContractLocation) {
		Object.assign(this, contractLocation);

		this.state = this.state ? new State(this.state) : this.state;
		this.utility = this.utility ? new Utility(this.utility) : this.utility;
		this.commissions = this.commissions ? this.commissions.map(c => new Commission(c)) : this.commissions;
		this.homeowner = this.homeowner ? new HomeOwner(this.homeowner) : this.homeowner;
		this.dropDate = this.dropDate ? new Date(moment(this.dropDate).format('MMM DD, YYYY hh:mm:ss a')) : this.dropDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.reinstatementDate = this.reinstatementDate
			? new Date(moment(this.reinstatementDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reinstatementDate;
		this.commissionPayableSchedules = this.commissionPayableSchedules
			? this.commissionPayableSchedules.map(c => new CommissionPayablesSchedule(c)) : this.commissionPayableSchedules;
		this.commissionPayables = this.commissionPayables
			? this.commissionPayables.map(l => new CommissionPayable(l)) : this.commissionPayables;
		this.pendingCommissions = this.pendingCommissions
			? this.pendingCommissions.map(p => new PendingCommission(p)) : this.pendingCommissions;
		this.commissionEstimates = this.commissionEstimates
			? this.commissionEstimates.map(c => new CommissionEstimate(c)) : this.commissionEstimates;
		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY hh:mm:ss a')) : this.effectiveDate;
		this.rejectionDate = this.rejectionDate
			? new Date(moment(this.rejectionDate).format('MMM DD, YYYY hh:mm:ss a')) : this.rejectionDate;
	}

	get isDropped(): boolean {
		return this.status === CONSTANTS.contractLocationStatuses.dropped;
	}

	get droppedText(): string {
		return `This account was dropped${this.dropDate ? ` on ${moment(this.dropDate).format('MM/DD/YYYY')}` : ''}`;
	}

	get hasForecastOverrides(): boolean {
		return false;
	}

	get displayStatus(): string {
		return CONSTANTS.contractLocationStatuses[this.status] || '';
	}

	get actualPayables(): Commission[] {
		return this.commissions ? this.commissions.filter(c => c.paidDate) : [];
	}

	public getDisplayAnnualUsage(unit?: string): string {
		const annualUsage = NumberUtils.amountToUnits(this.annualUsage, unit);

		return Math.round(annualUsage).toString();
	}

	public getFullAddress(withBreak = false): string {
		let fullAddress = '';

		fullAddress += this.address1 + ' ';
		fullAddress += this.address2 ? this.address2 + ' ' : '';
		if (withBreak) {
			fullAddress += '<br>';
		}
		fullAddress += this.city + ', ';
		fullAddress += this.state ? this.state.stateShort + ' ' : ' ';
		fullAddress += this.zipCode;

		return fullAddress;
	}
}
