import { AfterContentChecked, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'pk-broker-filter-dropdown',
	templateUrl: './filter-dropdown.component.html',
	styleUrls: ['./filter-dropdown.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		useExisting: FilterDropdownComponent,
	}],
})
export class FilterDropdownComponent<T> implements OnInit, ControlValueAccessor, OnChanges, AfterContentChecked {

	@Input()
	public option: T | null;
	private optionElement: HTMLElement;
	public disabled = false;
	private touched = false;

	@ViewChild('filterDropdown', { static: false }) filterDropdown: any;
	@ViewChild('dropdownToggle', { static: false }) dropdownToggle: ElementRef;
	@ViewChild('dropdownMenu', { static: false }) dropdownMenu: ElementRef;
	@ViewChild('scrollingArea', { static: false }) scrollingArea: ElementRef;
	@ViewChildren('optionsRef') optionsReferences: QueryList<ElementRef>;
	@ContentChild('name') public nameTemplate: TemplateRef<{ $implicit: T }>;
	@ContentChild('headerTemplate') public headerTemplate: TemplateRef<void>;
	@ContentChild('beforeLink') public beforeLink: TemplateRef<{ $implicit: T }>;
	@ContentChild('row') public rowRef: TemplateRef<{ $implicit: T }>;
	@ContentChild('footer') public footer: TemplateRef<void>;
	@Output() selection = new EventEmitter<T>();
	@Output() cleared = new EventEmitter<void>();

	@Input() label: string = null;
	@Input() insideClick = false;
	@Input() set isDisabled(disabled: boolean) { this.setDisabledState(disabled); }
	@Input() menuDisabled = false;
	@Input() headerText: string = null;
	@Input() nullOption: string = null;
	@Input() nullOptionSelectable = true;
	@Input() templateOnlyMenu = false;
	@Input() options: T[] | { [header: string]: T[] };
	@Input() initialOption: T = null;
	@Input() hideChevron = false;
	@Input() holdOption = true;
	@Input() scrollAreaHeight: 'medium' | null = null;
	@Input() showClear = false;
	@Input() headerLabelDisplayBlock = false;

	@Input() textKey = 'text';
	/**
	 * If this is defined, ensure that no two options map to the same value.
	 */
	@Input()
	valueKey: string;

	filterQuery = '';
	dropdownMenuBounds = null;
	dropdownToggleBounds = null;
	public dropdownAlignRight = false;

	@Input()
	textMap: (option: T) => string = option => String(typeof option === 'object' && this.textKey in option ? option[this.textKey] : option);

	/**
	 * If this is defined, ensure that no two options map to the same value.
	 */
	@Input()
	valueMap: (option: T) => any =
		option => (typeof option === 'object' && option !== null && this.valueKey in option) ? option[this.valueKey] : option;

	onChange = (_option: T) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	ngOnInit() {
		if (this.holdOption) {
			if (this.initialOption !== null) {
				this.option = this.initialOption;
			} else if (this.nullOption && this.nullOptionSelectable) {
				this.option = null;
			} else {
				this.option = this.hasHeaders ? this.options[this.headers[0]][0] : this.options[0];
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.option && changes.initialOption?.currentValue) {
			this.option = changes.initialOption.currentValue;
		}
	}

	ngAfterContentChecked(): void {
		if (this.dropdownMenu?.nativeElement) {
			this.dropdownMenuBounds = this.dropdownMenu.nativeElement.getBoundingClientRect();
		}
		if (this.dropdownToggle?.nativeElement) {
			this.dropdownToggleBounds = this.dropdownToggle.nativeElement.getBoundingClientRect();
		}

		if (!this.dropdownToggleBounds || !this.dropdownMenuBounds) {
			this.dropdownAlignRight = false;
		} else {
			const toggleBoxLeft = this.dropdownToggleBounds?.left ?? 0;
			const dropdownBoxWidth = this.dropdownMenuBounds?.width ?? 0;
			this.dropdownAlignRight = window.innerWidth - toggleBoxLeft <= dropdownBoxWidth + 1;
		}
	}

	get hasHeaders() {
		return !Array.isArray(this.options);
	}
	get headers() {
		return Object.keys(this.options);
	}

	onSelect(option: T, event: PointerEvent) {
		this.optionElement = event.target as HTMLElement;
		if (!this.disabled) {
			if (this.holdOption) {
				this.markAsTouched();
				this.option = option;
				this.onChange(this.valueMap(option));
			}
			this.selection.emit(this.valueMap(option));
		}
	}

	onClear() {
		this.cleared.emit(null);
	}

	writeValue(option: T) {
		if (this.hasHeaders) {
			this.option = option;
		} else {
			const targetValue = this.valueMap(option);
			this.option = (this.options as T[]).find(o => this.valueMap(o) === targetValue) ?? null;
		}
	}

	registerOnChange(onChange: (option: T) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	show() { this.filterDropdown.show(); }
	hide() { this.filterDropdown.hide(); }
	toggle() { this.filterDropdown.toggle(); }

	dropdownShown() {
		this.filterQuery = '';
		if (this.optionElement) {
			this.scrollingArea.nativeElement.scrollTop = this.optionElement.offsetTop;
		}
	}

	focusElement(event: MouseEvent) {
		this.dropdownToggle.nativeElement.focus();
		event.preventDefault();
	}

	textTyped(event: KeyboardEvent) {
		if (this.hasHeaders) { return; }

		if (event.key === ' ') {
			event.preventDefault();
		}
		if (event.key === 'Backspace') {
			this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 1);
		} else if (event.key.length === 1 && event.key !== ' ') {
			this.filterQuery += event.key.toLowerCase();
		} else {
			return;
		}
		setTimeout(() => {
			if (this.optionsReferences?.first) {
				this.optionsReferences.first.nativeElement.focus();
			} else if (this.filterDropdown._elementRef.nativeElement.firstChild.hasOwnProperty('focus')) {
				this.filterDropdown._elementRef.nativeElement.firstChild.focus();
			}
		}, 0);
	}

	get visibleOptions(): T[] {
		if (!this.hasHeaders) {
			return (this.options as T[]).filter(op => {
				const text = this.textMap(op).toLowerCase();
				let pos = 0;
				for (const letter of this.filterQuery) {
					const index = text.indexOf(letter, pos);
					if (index === -1) {
						return false;
					} else {
						pos = index + 1;
					}
				}
				return true;
			});
		} else {
			return [];
		}
	}

	public boldMap(str: string): string {
		const text = str.toLowerCase();
		const boldPositions = [];
		let pos = 0;
		for (const letter of this.filterQuery) {
			const index = text.indexOf(letter, pos);
			if (index === -1) {
				return str;
			} else {
				pos = index + 1;
				boldPositions.push(index);
			}
		}

		let result = '';
		let nextBoldPosition = boldPositions.shift();
		for (let i = 0; i < str.length; i++) {
			if (nextBoldPosition === i) {
				result += `<b>${str[i]}</b>`;
				nextBoldPosition = boldPositions.shift();
			} else {
				result += str[i];
			}
		}

		return result;
	}
}
