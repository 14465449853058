import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SecurityService } from 'src/app/security/security.service';
import { environment } from '../../../environments/environment';
import { AuthFields } from '../models';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(private securityService: SecurityService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const operationName = req.body && req.body.operationName ? req.body.operationName : '';
		const isRefresh = operationName.includes('RefreshToken');
		const isAuth = operationName.includes('AuthenticateUser');
		return from(this.securityService.getAuthFields(isRefresh)) // make sure we're always getting the latest token
			.pipe(
				switchMap(authFields => {
					const token = this.getToken(authFields, isRefresh);
					const clonedRequest = req.clone({
						headers: req.headers.set('Authorization', 'Bearer ' + token),
					});
					return next.handle(clonedRequest).pipe(
						map((result: HttpResponse<any>) => {
							if (result && result.body && result.body.errors) {
								throw result;
							}
							return result;
						}),
						catchError((error: any, caught: Observable<HttpEvent<any>>) => {
							if (!isRefresh && !isAuth && error.body && error.body.errors && error.body.errors.length) {
								const errors = error.body.errors.map(e => e.extensions?.code?.toLowerCase()).join(',');
								if (errors.includes('unauthenticated')) {
									this.securityService.logout();
								}
							}

							if (error.body && error.body.errors && error.body.errors.length) {
								return throwError(new Error(error.body.errors.map(e => e.message).join(',')));
							}
							return throwError(error);
						}),
					);
				}),
			);
	}

	private getToken(authFields: AuthFields, isRefresh: boolean): string {
		if (this.securityService.isDirect || !authFields?.token) {
			return environment.apiKey;
		}
		return isRefresh ? authFields.refreshToken : authFields.token;
	}
}
