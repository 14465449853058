<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title openAnim1" id="npsInitialHeader" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
			We want to hear from you!
		</h5>
		<h5 class="modal-title" id="npsConfirmationHeader" [ngClass]="{ openAnim2: closedForm, 'd-none': !closedForm }">Thank you for your feedback!</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<div class="row openAnim2" id="npsQuestion01" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
				<div class="col">
					<p class="label-style">How likely are you to recommend {{ parentBrokerage }} to a friend or colleague?</p>
				</div>
			</div>
			<div class="row openAnim2" id="npsQuestion01Btns" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
				<div class="col">
					<div class="btn-group btn-group-toggle d-flex" btnRadioGroup [(ngModel)]="userSurvey.recommendScore">
						<label class="btn btn-light" *ngFor="let rating of ratings" [ngClass]="{ active: userSurvey.recommendScore === rating }">
							<input type="radio" name="npsRating" id="radioRating{{ rating }}" [btnRadio]="rating" (click)="updateuserSurvey()" />{{ rating }}
						</label>
					</div>
				</div>
			</div>
			<div class="row openAnim2" id="npsQuestion01Legend" [ngClass]="{ 'd-none': closedForm, closeAnim: closingForm }">
				<div class="col font-size-075">Not at all likely</div>
				<div class="col text-right font-size-075">Extremley likely</div>
			</div>
			<div
				class="row"
				id="npsQuestion02"
				[ngClass]="{
					'd-none': !selectedScore || closedForm,
					openAnim1: selectedScore && !closingForm,
					closeAnim: closingForm,
				}">
				<div class="col mt-4 form-element-container">
					<label for="reasons">What are your primary reasons for the score you gave us?</label>
					<textarea class="form-control" id="reasons" rows="2" maxlength="1000" [(ngModel)]="userSurvey.scoreReason"></textarea>
				</div>
			</div>
			<div
				class="row"
				id="npsQuestion03"
				[ngClass]="{
					'd-none': !selectedScore || closedForm,
					openAnim2: selectedScore,
				}">
				<div class="col mt-4 form-element-container">
					<label for="roomForImprovement">Is there anything specific {{ parentBrokerage }} can do to improve your experience?</label>
					<textarea class="form-control" id="roomForImprovement" rows="2" maxlength="1000" [(ngModel)]="userSurvey.improveReason"></textarea>
				</div>
			</div>
			<div
				class="row"
				id="npsQuestion04"
				[ngClass]="{
					'd-none': !selectedScore || closedForm,
					openAnim3: selectedScore,
				}">
				<div class="col mt-4">
					<p class="label-style">Would it be okay for us to follow up with you about your responses?</p>
				</div>
			</div>
			<div
				class="row"
				id="npsChoices04"
				[ngClass]="{
					'd-none': !selectedScore || closedForm,
					openAnim3: selectedScore,
				}">
				<div class="col form-element-container">
					<div class="custom-control custom-radio">
						<input
							type="radio"
							id="yesToContact"
							name="okToContact"
							class="custom-control-input"
							[value]="true"
							[(ngModel)]="userSurvey.canContact" />
						<label class="custom-control-label" for="yesToContact">Yes</label>
					</div>
				</div>
				<div class="col form-element-container">
					<div class="custom-control custom-radio">
						<input
							type="radio"
							id="noToContact"
							name="okToContact"
							class="custom-control-input"
							[value]="false"
							[(ngModel)]="userSurvey.canContact"
							(click)="closeBestEmail()" />
						<label class="custom-control-label" for="noToContact">No</label>
					</div>
				</div>
			</div>
			<div
				class="row"
				id="npsQuestion05"
				[ngClass]="{
					'd-none': (!userSurvey.canContact && !closingBestEmail) || closedForm,
					openAnim2: userSurvey.canContact,
					closeAnim: closingBestEmail,
				}">
				<div class="col mt-3 form-element-container">
					<label for="emailAddress">What's the best email adress to reach you?</label>
					<input type="text" class="form-control" id="emailAddress" placeholder="name@email.com" [(ngModel)]="userSurvey.bestEmail" />
				</div>
			</div>
			<div
				class="row"
				id="npsSubmitDiv"
				[ngClass]="{
					'd-none': !selectedScore || closedForm,
					openAnim4: selectedScore && !closingForm,
					closeAnim: closingForm,
				}">
				<div class="col mt-3 text-right">
					<button type="button" class="btn btn-primary" id="npsSubmitBtn" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
				</div>
			</div>
			<div class="row" id="npsConfirmationMsg" [ngClass]="{ openAnim2: closedForm, 'd-none': !closedForm }">
				<div class="col">
					<p class="label-style">We take every opinion seriously and will be reaching out if you wanted us to. Thank you again!</p>
					<p class="label-style">You can close this window by clicking the X above.</p>
				</div>
			</div>
		</div>
	</ng-template>
</pk-broker-modal>
