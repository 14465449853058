import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ActualRouteInterceptor implements HttpInterceptor {
	private actualRoute: string = '';

	constructor(private router: Router) {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
			this.actualRoute = event.urlAfterRedirects;
		});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const modifiedReq = req.clone({
			headers: req.headers.set('X-Actual-Route', this.actualRoute),
		});
		return next.handle(modifiedReq);
	}
}
