import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CONSTANTS, NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { LoadFactorService } from 'src/app/load-factor/load-factor.service';
import { Contract, MarginAdjustment, ServiceTypeUnit, User } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends AbstractPageForm {

	@Input() contract: Contract;
	@Input() loggedInUser: User;
	@Input() hideCloseButton: boolean;
	@Input() isLoadFactor: boolean;
	@Input() isNYMEXAlert: boolean;
	@Input() isMarketClosedAlert: boolean;
	@Input() isSmall: boolean;
	@Input() hide: boolean;

	//For automated renewal
	@Input() serviceTypeUnit: ServiceTypeUnit;
	@Input() pageTitle: string;

	@ViewChild('alert') private alert: ElementRef;

	public show = true;
	public header = '';
	public headerClass = '';
	public iconClass = '';
	public helperLink = '';
	public marginAdjustments: MarginAdjustment[] = [];

	constructor(
		private loadFactorService: LoadFactorService,
		private fb: FormBuilder,
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
	) {
		super();
		this.submitText = 'Send Email';
	}

	public async loadPageData() {
		if (this.showAutomatedRenewal) {
			const result = await this.graphqlService.getMarginAdjustments(
				this.contract?.serviceTypeId,
				this.contract?.stateId,
				this.contract?.locations[0]?.utilityId,
				0,
			);
			this.marginAdjustments = result.data.serviceType.marginAdjustments;
		}
	}

	public getForm() {
		return this.fb.group({
			margin: [NumberUtils.round(this.contract?.margin, 6), [Validators.required]],
		});
	}

	public async onFormSubmitted() {
		await this.graphqlService.sendAutomatedRenewalEmail(this.contract?.renewalContract?.contractId, this.margin);
		this.hideAlert();
		this.toastrService.success('Your proposal has been sent. A copy will also be sent to you.', this.pageTitle);
	}

	get showAutomatedRenewal() {
		return this.contract?.showAutomatedRenewalNotification;
	}

	get margin() { return this.form.margin.value; }

	get notificationText(): string {
		if (this.contract) {
			if (this.showAutomatedRenewal) {
				this.header = 'Your renewal pricing is ready';
			}
			if (this.isLoadFactor) {
				this.header = 'Our Advice:';
				return this.loadFactorService.getAdvice(this.contract);
			}
			return this.getContractNotificationText();
		} else if (this.isNYMEXAlert) {
			this.header = 'Pricing Help:';
			return this.getNYMEXNotificationText();
		} else if (this.isMarketClosedAlert) {
			this.header = 'Pricing Help:';
			return 'During weekends/holidays, the market is closed and any pricing done is subject to rate change.';
		}

		return '';
	}

	get alertIcon(): string {
		if (this.contract) {
			return 'exclamation-circle';
		}

		return 'comments-dollar';
	}

	get isEnterpiseAgent(): boolean {
		return this.loggedInUser?.agent && this.loggedInUser?.agent?.isEnterprise;
	}

	public getNYMEXNotificationText(): string {
		return `<p>Experiencing a significant dip in prices, some contracts are trading at 52-week lows. Now is a great time to buy.`
			+ `<strong><a href="https://assets.powerkiosk.com/includes/market/Why-Procure.pdf" target="_blank" download>`
			+ ` See more here.</a></strong></p>`;
	}

	public getContractNotificationText(): string {
		// for now return nothing
		if (this.contract?.isResidential) { return ''; }

		const email = this.loggedInUser.agent && this.loggedInUser.agent.parentId
			? this.loggedInUser.agent.parent.email
			: 'deals@powerkiosk.com';
		const validMatrixDate = moment(this.contract?.contractDate).format('MM/DD/YYYY');
		const validUploadDate = moment(this.contract?.uploadDate ? this.contract?.uploadDate : new Date()).format('MM/DD/YYYY');
		const hasCustomerContractEsignRequestEmail = this.contract?.agent.emailPreferences
			?.some(pref => pref.slug === 'customer-contract-esign-request-email') &&
			this.contract?.customer.emailPreferences
				?.some(pref => pref.slug === 'customer-contract-esign-request-email');

		switch (this.contract?.status) {
			case CONSTANTS.statuses.confirmed: {
				if (this.showAutomatedRenewal) {
					return 'Send your customer the best rate and a proposal via email. '
						+ 'Simply select your margin for the new pricing and click "Send Email".';
				}
				break;
			}
			case CONSTANTS.statuses.acomp: {
				if (this.contract?.awardDate && !this.contract?.uploadDate && this.contract?.supplierId) { // award bid
					return `The contract has been awarded to ${this.contract?.supplier.name}.  `
						+ `It can take up to <strong>three business hours</strong> for the contract to be emailed to `
						+ `your client for signature. If after that time has passed and your customer has not received `
						+ `an email for signature, please email <a href="mailto:${email}">${email}</a> with your `
						+ `inquiry and include the contract number for reference.`;
				} else if (this.contract?.awardDate && this.contract?.uploadDate && this.contract?.supplierId) {
					if (hasCustomerContractEsignRequestEmail && this.contract?.isSentToCustomer) {
						return `This contract has been successfully sent to the customer for signature via email. `
							+ `Remind them that the contract is valid until 5PM CT on ${validUploadDate}.`;
					} else {
						return `This contract has been successfully downloaded and a copy was sent to your email. Please remember `
							+ `that pricing is valid until 5PM CT on ${validUploadDate}.`;
					}
				}
				break;
			}
			case CONSTANTS.statuses.ainp: {
				if (!this.contract?.rfqSession.isOnHold) {
					return `Your pricing session was successfully started. Your pricing session is set to end on `
						+ `${this.contract?.rfqSession.formatEndDate(false)} CT. `
						+ `Since pricing is only valid until close of business the day it's provided, suppliers typically `
						+ `provide pricing the day a pricing session ends to ensure it's valid. Check back for pricing at that time.`;
				} else {
					return `Our Operations Staff is reviewing your pricing session and will reach out with any questions as necessary.`;
				}
			}
			case CONSTANTS.statuses.quote: {
				if (this.contract?.supplierId && !this.contract?.isResidential) { // select matrix
					if (hasCustomerContractEsignRequestEmail && this.contract?.isSentToCustomer) {
						return `This contract has been successfully sent to the customer for signature via email. `
							+ `Remind them pricing is valid until 5PM CT on ${validMatrixDate}.`;
					} else {
						return `This contract has been successfully downloaded and a copy was sent to your email. Please remember `
							+ `that pricing is valid until 5PM CT on ${validUploadDate}.`;
					}
				}
				break;
			}
			case CONSTANTS.statuses.rejected:
			case CONSTANTS.statuses.dropped: {
				if (this.contract?.rejectReason) {
					return this.contract?.rejectReason;
				}
				break;
			}
			case CONSTANTS.statuses.signed: {
				if (this.contract?.isResidential && !this.contract?.externalId) {
					return `This contract has been authorized by the customer for enrollment, and now we are waiting on the supplier `
						+ `to confirm this contract.  This can typically take 2-3 business days for non-immediate switches, and between `
						+ `1-2 days for immediate switches please submit a support ticket if this contract is still not confirmed if `
						+ `that time has passed.`;
				}
				break;
			}
			case CONSTANTS.statuses.expired: {
				if (this.contract?.supplierId && this.contract?.isResidential && !this.contract?.externalId) {
					return this.contract?.rejectReason;
				} else if (this.contract?.supplierId) {
					return `This contract is no longer valid, please create a support ticket with any questions regarding`
						+ ` this contract and include the contract number in your inquiry.`;
				}
				break;
			}
		}

		return '';
	}

	public hideAlert(): void {
		this.show = false;
		setTimeout(() => {
			this.alert.nativeElement.remove();
		}, 1000);
	}
}
