import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { NpsSurveyModalComponent } from './modals/nps-survey-modal/nps-survey-modal.component';

@NgModule({
	imports: [CommonModule, SharedModule],
	declarations: [NpsSurveyModalComponent],
})
export class NpsSurveyModule {}
