import * as moment from 'moment-timezone';

import { Supplier } from './';
import { CommissionPayPlanType } from './commission-pay-plan-type';
import { ServiceType } from './service-type';
import { State } from './state';

export class SupplierCommissionRule {

	public id: number;
	public commissionCutOff: string;
	public serviceTypeId: string;
	public supplierId: string;
	public upfrontPercentage: number;
	public discountFactor: number;
	public upfrontLag: number;
	public monthlyLag: number;
	public upfrontDateType: number;
	public showUpfrontRequest: boolean;
	public hasAnniversaryUpfront: boolean;
	public anniversaryUpfrontDateType: number;
	public minUpfrontTermFactor: number;
	public maxUpfrontTermFactor: number;
	public hasResidentialUpfront: boolean;
	public residentialPayLag: number;
	public residentialResidualLag: number;
	public residentialPayAmount: number;
	public residentialResidualAmount: number;
	public residentialMargin: number;
	public marginCap: number;
	public marginSplit: number;
	public commissionCutoff: string;
	public hasCommercial: boolean;
	public hasResidential: boolean;
	public residentialDescription: string;
	public commercialDescription: string;
	public contractStartDate: string | Date;
	public contractEndDate: string | Date;
	public maxAdvanceMonths: number;
	public maxUpfrontAmount: number;
	public maxUpfrontAnnualUsage: number;
	public lagCutOffDay: number;
	public anniversaryLag: number;
	public clawbackLag: number;
	public reinstatementLag: number;
	public residualFactor: number;
	public paymentInterval: number;
	public acceptsResidentialRenewals: boolean;
	public residentialRenewalPayLag: number;
	public residentialRenewalResidualLag: number;
	public residentialRenewalUpfrontAmount: number;
	public residentialRenewalResidualAmount: number;
	public residentialRenewalMargin: number;
	public residentialRenewalWindow: number;

	public states: State[];
	public serviceType: ServiceType;
	public supplier: Supplier;
	public commissionPayPlanTypes: CommissionPayPlanType[];

	constructor(supplierCommissionRule: SupplierCommissionRule) {
		Object.assign(this, supplierCommissionRule);

		this.contractStartDate = this.contractStartDate
			? new Date(moment(this.contractStartDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractStartDate;
		this.contractEndDate = this.contractEndDate
			? new Date(moment(this.contractEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractEndDate;
	}
}
