import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CONSTANTS } from '@pk/powerkioskutils';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { FaqSearchModalComponent } from 'src/app/faq/modals/faq-search-modal/faq-search-modal.component';
import { SecurityService } from 'src/app/security/security.service';
import { HelperService } from 'src/app/shared/helper.service';
import { AgentDiscountProgram, Breadcrumb, Company, Logo, Menu, User, UserEmail } from 'src/app/shared/models';
import { environment } from '../../../environments/environment';
import { GraphqlService } from '../../graphql/graphql.service';
import { Agent, Attachment } from '../../shared/models';
import { AppStateService } from '../app-state.service';

@Component({
	selector: 'pk-broker-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	@Output() userUpdated: EventEmitter<User> = new EventEmitter();
	@Output() agentUpdated: EventEmitter<Agent> = new EventEmitter();
	@Output() useDefaultUpdated: EventEmitter<boolean> = new EventEmitter();
	@Output() navbarLogoUpdated: EventEmitter<Logo> = new EventEmitter();
	@Output() footerLogoUpdated: EventEmitter<Logo> = new EventEmitter();

	@Input() isDirect = false;
	@Input() isPopupWindow = false;

	public useDefault: boolean;
	public user: User;
	public breadcrumbs = [];
	public searchForm: FormGroup<{ search: FormControl<string> }>;
	public environment = environment;
	public loading = true;
	public window = window;
	public useSupportSystem = true;
	public supportEmail = '';
	public attachment: Attachment;
	public menus: Menu[];
	public unreadUpdatedCount: number = null;
	public agentDiscountPrograms: AgentDiscountProgram[];
	public route: any;
	public showFinancialProfile: boolean;
	public showPricingAnalytics: boolean;
	public isFinancialProfileCollapsed = true;
	public isMenuCollapsed = true;
	public isFromToggle = false;

	public defaultNavbarLogo: Logo;
	public defaultFooterLogo: Logo;
	public envName = `broker${environment.name}`;

	constructor(
		private securityService: SecurityService,
		private graphqlService: GraphqlService,
		private modalService: BsModalService,
		private toastrService: ToastrService,
		private appStateService: AppStateService,
		private router: Router,
		private fb: FormBuilder,
	) {
		this.appStateService.onSetBreadcrumbs.subscribe((breadcrumbs: Breadcrumb[]) => {
			Promise.resolve().then(() => {
				this.breadcrumbs = breadcrumbs;
			});
		});
		this.appStateService.onUserUpdated.subscribe(async () => {
			this.loading = true;
			await this.getNavbarData();
			this.loading = false;
		});
		this.appStateService.onAgentUpdated.subscribe(async (agentId: string) => {
			this.loading = true;
			await this.getNavbarAgentData(agentId);
			this.loading = false;
		});
		this.appStateService.onSetDefault.subscribe(async (companyId: string) => {
			this.loading = true;
			await this.getNavbarCompanyData(companyId);
			this.loading = false;
		});
	}

	@HostListener('document:click', ['$event']) public onClick(event: any): void {
		return this.collapseMenu();
	}

	get loggedInUser() {
		return this.securityService.authFields?.loggedInUser;
	}

	async ngOnInit(): Promise<void> {
		if (!this.isDirect && this.loggedInUser) {
			await this.getNavbarData();
			this.buildSearchForm();
		}

		this.loading = false;
	}

	private async getNavbarCompanyData(companyId: string): Promise<void> {
		try {
			const result = await this.graphqlService.getNavbarCompanyData(companyId);
			this.setDefaultLogo(result.data.company);
		} catch (e) {
			this.toastrService.warning(
				'There was a problem loading the page. We have been notified and are working to fix the issue. ' + 'Please check back again in 30 minutes.',
				'Warning',
			);
		}
	}

	private async getNavbarAgentData(agentId: string): Promise<void> {
		this.loading = true;
		try {
			const result = await this.graphqlService.getNavbarAgentData(agentId);
			this.user = new User();
			this.user.phone = result.data.agent.phone;
			this.user.emails = [{ email: new Agent(result.data.agent).email } as UserEmail];
			this.user.agent = result.data.agent;
			this.user.company = this.user.agent.company;
			this.setDefaultLogo(this.user.agent.company);

			this.userUpdated.emit(this.user);
			this.agentUpdated.emit(this.user.agent);
		} catch (e) {
			this.toastrService.warning(
				'There was a problem loading the page. We have been notified and are working to fix the issue. ' + 'Please check back again in 30 minutes.',
				'Warning',
			);
		}
		this.loading = false;
	}

	private async getNavbarData(): Promise<void> {
		const result = await this.graphqlService.getNavbarData();

		this.user = this.loggedInUser;
		if (this.user.agent) {
			this.agentDiscountPrograms = result.data.agentDiscountPrograms.message.map(r => new AgentDiscountProgram(r));
			this.user.phone = this.user.agent.phone;
			this.user.emails = [{ email: this.user.agent.email } as UserEmail];
		}

		if (!this.user.company) {
			await this.getNavbarCompanyData(CONSTANTS.companies.powerKiosk);
		} else {
			this.setDefaultLogo(this.user.company);
		}

		this.showFinancialProfile =
			this.user.hasCommission &&
			this.user.agent &&
			!this.user.agent.isEnterprise &&
			this.user.agent.isActive &&
			(this.user.agent.parent
				? this.user.agent.showFinancialProfile && this.user.agent.parent.showFinancialProfile
				: this.user.agent.showFinancialProfile);

		this.supportEmail = '';
		if (this.user.agent && this.user.agent.parent) {
			this.supportEmail = this.user.agent.parent.supportEmail;
		}

		this.userUpdated.emit(this.user);
		this.agentUpdated.emit(this.user.agent);
	}

	public logout(): void {
		this.securityService.logout();
	}

	get search() {
		return this.searchForm.get('search');
	}
	get isImpersonating() {
		return this.securityService.isImpersonating;
	}

	private isAtLeast4Characters(): boolean {
		if (!this.search.value) {
			return false;
		}
		this.search.setValue(this.search.value.replace(/\s{2,}/g, ' ').trim());
		this.search.updateValueAndValidity();
		return this.search.value.length >= 4;
	}

	private setDefaultLogo(company: Company): void {
		this.defaultNavbarLogo = company.defaultNavbarLogo;
		this.defaultFooterLogo = company.defaultFooterLogo;

		this.footerLogoUpdated.emit(this.defaultFooterLogo);
	}

	public onSubmit(): void {
		const lengthIsValid = this.isAtLeast4Characters();
		if (this.searchForm.valid && lengthIsValid) {
			this.router.navigate(['/search/result', this.searchForm.value.search]).then(() => {
				this.searchForm.reset();
			});
		} else {
			if (this.search.errors || !lengthIsValid) {
				this.toastrService.warning('Must be at least 4 characters to search!', 'Search Result');
			}
		}
	}

	public openFaqSearch(): void {
		this.modalService.show(FaqSearchModalComponent, {
			class: 'pk-modal modal-dialog-slideout',
			ignoreBackdropClick: true,
		});
	}

	private buildSearchForm(): void {
		this.searchForm = this.fb.group({
			search: this.fb.control('', [Validators.required]),
		});
	}

	public navigateHome(): void {
		if (!this.isDirect) {
			if (window.location.pathname === '/contract') {
				window.location.reload();
			} else {
				this.router.navigate([environment.contracts.home]);
			}
		}
	}

	public navigate(route: string): boolean {
		if (window.location.pathname === '/contract' && route === '/contract') {
			window.location.reload();
			return false;
		} else {
			return true;
		}
	}

	get showBranding(): boolean {
		return !this.loading && (!this.isDirect || !!this.defaultNavbarLogo || (!!this.user?.agent && (this.isDirect || !!this.defaultNavbarLogo)));
	}

	public toggleFinancialProfileProgram(toggle: boolean): void {
		this.isFinancialProfileCollapsed = toggle;
	}

	public toggleMenu(): void {
		this.isFromToggle = true;
		this.isMenuCollapsed = !this.isMenuCollapsed;
	}

	public collapseMenu(): void {
		if (!this.isFromToggle) {
			this.isMenuCollapsed = true;
		}

		this.isFromToggle = false;
	}

	public runMethod(method: string): void {
		this[method]();
	}
}
