import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS, StringUtils } from '@pk/powerkioskutils';

import _ from 'lodash';
import moment from 'moment-timezone';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AgentAgreementModalComponent } from './agent/modals/agent-agreement/agent-agreement.component';
import { AgentBankAccountsReminderModalComponent } from './agent/modals/agent-bank-accounts-reminder-modal/agent-bank-accounts-reminder-modal.component';
import { AgentMarketOpportunitiesModalComponent } from './agent/modals/agent-market-opportunities-modal/agent-market-opportunities-modal.component';
import { AgentReactivationModalComponent } from './agent/modals/agent-reactivation-modal/agent-reactivation-modal.component';
import { AppStateService } from './core/app-state.service';
import { GraphqlService } from './graphql/graphql.service';
import { NpsSurveyModalComponent } from './nps-survey/modals/nps-survey-modal/nps-survey-modal.component';
import { SecurityService } from './security/security.service';
import { TopBannerAlertLearnMoreModalComponent } from './shared/modals/top-banner-alert-learn-more-modal/top-banner-alert-learn-more-modal.component';
import { Agent, Logo, User } from './shared/models';
import { UserSurvey } from './shared/models/user-survey';

declare const $: any;

@Component({
	selector: 'pk-broker-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public appReady: boolean;
	public directReady: boolean;
	public isPopupWindow: boolean;

	public useDefault: boolean;
	public user: User;
	public agent: Agent;
	public feedbackPage: string;
	public defaultFooterLogo: Logo;

	constructor(
		private appStateService: AppStateService,
		private securityService: SecurityService,
		private graphqlService: GraphqlService,
		private router: Router,
		private modalService: BsModalService,
		private route: ActivatedRoute,
	) {
		this.appStateService.onReady.subscribe((ready: { app: boolean; direct: boolean; isPopupWindow: boolean }) => {
			this.appReady = ready.app;
			this.directReady = ready.direct;
			this.isPopupWindow = ready.isPopupWindow;
		});
		this.appStateService.onShowFeedback.subscribe((page: string) => {
			this.feedbackPage = page;
		});
	}

	ngOnInit() {
		// eslint-disable-next-line space-before-function-paren
		$(document).on('wheel', 'input[type=number]', function () {
			$(this).blur();
		});
	}

	public async onUserUpdated(user: User): Promise<void> {
		this.user = user;
		const isImpersonating = this.securityService.isImpersonating;

		if (!this.user.isSaas && !isImpersonating && this.user.agent && this.securityService.authFields) {
			const npsSurveySetting = this.user.getSurveySetting(CONSTANTS.userSurveyCategories.npsQuarterly);

			if (
				!this.user.agent.parent &&
				!this.user.agent.isEnterprise &&
				!this.user.agent.isEmployee &&
				(this.user.showTermsOfService || this.user.showCompliance)
			) {
				this.modalService.show(AgentAgreementModalComponent, {
					ignoreBackdropClick: true,
					keyboard: false,
					backdrop: 'static',
					class: 'pk-modal modal-lg',
				});
			} else if (!this.user.agent?.isActive && !this.user.agent.parent) {
				this.modalService.show(AgentReactivationModalComponent, {
					class: 'pk-modal modal-dialog-centered modal-md',
				});
			} else if (
				!this.user.agent.parent &&
				!this.user.agent.isEnterprise &&
				!this.user.agent.isEmployee &&
				!this.user.agent.bankAccounts.length &&
				this.user.agent.hasConfirmedContracts &&
				!this.router.routerState.snapshot.url.includes('security/edit')
			) {
				this.modalService.show(AgentBankAccountsReminderModalComponent, {
					class: 'pk-modal modal-dialog-centered modal-md',
				});
			} else if (npsSurveySetting?.show) {
				const result = await this.graphqlService.createUserSurvey({
					userSurveyCategoryId: CONSTANTS.userSurveyCategories.npsQuarterly,
				});
				const userSurvey = new UserSurvey(result.data.createUserSurvey);
				const modalRef = this.modalService.show(NpsSurveyModalComponent, {
					class: 'modal-dialog-centered nps-csat-modal',
					initialState: { userSurvey, user },
				});
				const npsSub = modalRef.onHidden.subscribe(() => {
					npsSub.unsubscribe();
					if (!userSurvey.closedSurveyWithButton) {
						userSurvey.closedSurveyWithoutButton = true;
						userSurvey.isComplete = true;
					}
					if (!userSurvey.canContact) {
						userSurvey.bestEmail = null;
					}
					this.graphqlService.updateUserSurveySetting(npsSurveySetting.id, { show: false });
					this.graphqlService.updateUserSurvey(userSurvey.id, _.omit(userSurvey, 'id'));
				});
			} else if (
				this.user.agent.marketOpportunitiesAlertEffectiveDate &&
				moment().isAfter(moment(this.user.agent.marketOpportunitiesAlertEffectiveDate))
			) {
				const modalRef = this.modalService.show(AgentMarketOpportunitiesModalComponent, {
					ignoreBackdropClick: true,
					keyboard: false,
					backdrop: 'static',
					class: 'pk-modal modal-lg',
				});
				const marketSub = modalRef.onHidden.subscribe(() => {
					marketSub.unsubscribe();
					// run this outside the processing loop
					this.graphqlService.updateAgent(this.user.agentId, {
						extendMarketOpportunitiesAlert: true,
					});
				});
			} else if (!this.user.agent.parent && StringUtils.toBoolean(this.route.snapshot.queryParamMap.get('showReferralPopup'))) {
				const modalRef = this.modalService.show(TopBannerAlertLearnMoreModalComponent, {
					class: 'pk-modal modal-dialog-centered',
					initialState: {
						type: CONSTANTS.bannerAlertTypes.brokerReferral,
					},
				});
				const learnMoreSub = modalRef.onHidden.subscribe(() => {
					learnMoreSub.unsubscribe();
					this.router.navigate([window.location.pathname]);
				});
			}
		}
		if (this.user.isSaas || this.user.isAdmin || isImpersonating) {
			window['ga-disable-GTM-5HPC63W'] = true;
		}
	}

	public onAgentUpdated(agent: Agent): void {
		this.agent = agent;
	}

	public onUseDefaultUpdated(useDefault: boolean): void {
		this.useDefault = useDefault;
	}

	public onFooterLogoUpdated(logo: Logo): void {
		this.defaultFooterLogo = logo;
	}
}
