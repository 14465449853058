<div class="row d-flex justify-content-end">
	<div class="col-auto">
		<a
			class="collapse-link collapsed"
			(click)="toggleFinancialProfileProgram()"
			href="javascript:void(0)"
			role="button"
			aria-expanded="false"
			aria-controls="freqBroker">
			<fa-icon [icon]="['fas', 'times']"></fa-icon>
		</a>
	</div>
</div>
<div class="row d-flex justify-content-center fin-profile-content">
	<div class="financial-profile" [ngClass]="{ 'col-12 col-xl-7': user.agent.isBusinessFull, 'col-12 col-xl': !user.agent.isBusinessFull }">
		<div class="row">
			<div class="col-12">
				<h2 class="mb-0">Your Financial Profile</h2>
				<p>
					<a
						[routerLink]="['/report', 'agent-financial-profile-breakdown', '771b936c83dc456a88dd07280dd3a459']"
						(click)="toggleFinancialProfileProgram(); sendPageEvent('financial-profile-report')"
						class="blue-area-link">
						How are these numbers determined?
					</a>
				</p>
			</div>
		</div>
		<div class="row">
			<div class="col col-sm-auto col-md-4">
				<p class="blue-area-label">
					Reversible Commission
					<a
						class="tooltip-icon"
						placement="top"
						tooltip="Money that has been fronted to you based on forecasted usage but not yet fully realized by actual flow.">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ reversibleCommission | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4 px-md-1 px-lg-3 px-xl-1 px-xxl-3">
				<p class="blue-area-label">Fulfilled Term Value</p>
				<p class="blue-area-data-item">{{ flowedTermValue | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4">
				<p class="blue-area-label">
					Maximum Upfront Allowance
					<a
						href="javascript:void(0)"
						placement="top"
						tooltip="The total estimated commission earnings to be paid in the future."
						class="tooltip-icon">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ maxReversibleCommissionOverride || maxReversibleCommission | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4">
				<p class="blue-area-label">
					Actual Payables for {{ lastMonthDisplay }}
					<a class="tooltip-icon" placement="top" tooltip="How much money was paid to you last month based on actual usage.">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ commissionPaidLastMonth | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4 px-md-1 px-lg-3 px-xl-1 px-xxl-3">
				<p class="blue-area-label">
					Forecasted Payables for {{ currMonthDisplay }}
					<a class="tooltip-icon" placement="top" tooltip="How much money is expected to be paid to you this month based on historical usage.">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ estimatedCommissionCurrMonth | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4">
				<p class="blue-area-label">
					Forecasted Future Payables
					<a class="tooltip-icon" placement="top" tooltip="The total forecasted commission earnings to be paid in the future.">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ estimatedFutureCommission | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col col-sm-auto col-md-4">
				<p class="blue-area-label">
					Drop Ratio
					<a
						class="tooltip-icon"
						placement="top"
						tooltip="The total sum of term value that has drop divided by the total sum of term value that you have booked (confirmed and dropped).">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
					</a>
				</p>
				<p class="blue-area-data-item">{{ agentDropRatio | number: '1.0-0' }}%</p>
			</div>
			<div class="col-12 col-md-8 pl-md-1 pl-lg-3 pl-xl-1 pl-xxl-3">
				<div class="row">
					<div class="col-12 py-0">
						<hr class="blue-area-divider" />
					</div>
				</div>
				<div class="row mb-4 mb-xl-0">
					<div class="col-12 col-sm">
						<p>
							<a
								[routerLink]="['/report', 'commission-forecast', 'df5ed1f6cf1c4c98ad27c56385bb390d']"
								(click)="toggleFinancialProfileProgram(); sendPageEvent('commission-forecast-report')"
								class="blue-area-link">
								<fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
								Forecasted Payables Report
							</a>
						</p>
					</div>
					<div class="col-12 col-sm">
						<p>
							<a
								[routerLink]="['/report', 'pendingCommission', '38f3d36b971f4e67ad818d8bb08e1983']"
								(click)="toggleFinancialProfileProgram(); sendPageEvent('commission-report')"
								class="blue-area-link">
								<fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon>
								Actual Payables Report
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row pt-2 pt-sm-0" [ngClass]="{ 'pt-2 pt-sm-0': user.agent.isBusinessFull }" *ngIf="negativeBalance">
			<div class="col-auto pr-0 alert-icon"><fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon></div>
			<div class="col-10 col-sm-auto">
				<h2 class="blue-area-label white-text">You have a balance</h2>
				<p class="blue-area-data-item">{{ negativeBalance | currency: 'USD' : 'symbol' : '1.2-2' }}</p>
			</div>
			<div class="col-12 col-sm">
				<p class="blue-area-label mb-2">
					<ng-container *ngIf="negativeBalance < 0; else isPositiveBalance">
						A negative balance has been incurred due to problems with your deals. Negative balances that are not corrected are paid off with future
						earnings.
					</ng-container>
					<ng-template #isPositiveBalance>
						A positive balance has been incurred because we do not issue payouts for payments less than $25, which makes it possible for your account
						to remain in a positive state.
					</ng-template>
				</p>
			</div>
		</div>
	</div>
	<div class="col-12 col-xl-5 pb-4 pb-sm-3" *ngIf="user.agent.isBusinessFull">
		<div class="row">
			<div class="col-12 mb-3">
				<h2 class="mb-0">Your Commission Rates</h2>
				<table class="table table-sm">
					<thead>
						<tr>
							<th scope="col" class="no-top-border">Plan</th>
							<th scope="col" class="no-top-border">Rate (Up To)</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let commissionPayPlanOptionStat of user.agent.commissionPayPlanOptionStats">
							<td scope="row">{{ commissionPayPlanOptionStat.description }}</td>
							<td>{{ commissionPayPlanOptionStat.maxRate }}%</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row" *ngIf="showFrequentBroker">
			<div class="col-12 pb-4 pb-lg-1 pb-xl-0">
				<div class="row d-flex align-items-xl-end">
					<div class="col-12 col-lg-9 col-xl-auto pr-xl-1 pr-xxl-3">
						<div class="row">
							<div class="col-12">
								<h2>Your Frequent Broker Stats</h2>
							</div>
						</div>
						<div class="row d-none d-md-flex financial-profile-levels">
							<ng-container *ngFor="let agentDiscountProgram of agentDiscountPrograms; let i = index">
								<div
									[class]="'col-auto fb-level ' + getLevelStyle(agentDiscountProgram, i)"
									[ngClass]="{
										'not-reached': user.agent.volume < agentDiscountProgram.minVolume,
										'pl-3 pr-0': i === 0,
										'px-0': i > 0 && i < agentDiscountPrograms.length - 1,
										'pl-0': i === agentDiscountPrograms.length - 1,
									}">
									<p class="fb-emblem"><fa-icon [icon]="user.agent.getLevelIcon(agentDiscountProgram)"></fa-icon></p>
									<p class="fb-level-name">{{ agentDiscountProgram.name }}</p>
									<p class="fb-level-percentage">{{ agentDiscountProgram.rate | number }}%</p>
								</div>
								<div
									class="col-auto px-1 fb-level-path"
									*ngIf="i < agentDiscountPrograms.length - 1"
									[ngClass]="{ 'not-reached': user.agent.volume < agentDiscountProgram.minVolume }">
									<p class="d-none d-md-block fb-path-number">{{ getMaxVolume(agentDiscountProgram) }}</p>
									<p class="fb-path-arrow"><img src="https://assets.powerkiosk.com/includes/images/fb-arrow.png" alt="arrow" /></p>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="col-12 col-lg-3 col-xl-auto">
						<div class="row">
							<div class="col-12 col-sm-auto">
								<p class="blue-area-label">Additional Commission Unlocked</p>
								<p class="blue-area-data-item"><span class="smaller-text"> Your Rate + </span>{{ user.agent.discountProgram?.rate | number }}%</p>
							</div>
							<div class="col-12 col-sm-auto" *ngIf="nextAgentDiscountProgram">
								<p class="blue-area-label">
									Volume needed to unlock <span [ngClass]="nextLevelStyle">{{ nextAgentDiscountProgram.name }}</span>
								</p>
								<p class="blue-area-data-item">{{ volumeToNextDiscountProgram | currency: 'USD' : 'symbol' : '1.0-0' }}</p>
							</div>
							<div class="col-12 col-sm-auto">
								<p class="blue-area-label">
									Your Remaining Term Value
									<a class="tooltip-icon" placement="top" [tooltip]="totalRemainingText" *ngIf="nextAgentDiscountProgram">
										<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
									</a>
								</p>
								<p class="blue-area-data-item">{{ user.agent.volume | currency: 'USD' : 'symbol' : '1.0-0' }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
