import { Component, OnInit } from '@angular/core';

import _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from 'src/app/shared/helper.service';
import { User, UserSurvey } from 'src/app/shared/models';

@Component({
	selector: 'pk-broker-nps-survey-modal',
	templateUrl: './nps-survey-modal.component.html',
	styleUrls: ['./nps-survey-modal.component.scss'],
})
export class NpsSurveyModalComponent implements OnInit {
	// inputs
	public userSurvey: UserSurvey;
	public user: User;

	public ratings = [];

	public closingBestEmail = false;
	public closingForm = false;
	public closedForm = false;

	public submitDisabled = false;
	public submitText = 'Submit';

	constructor(
		private graphqlService: GraphqlService,
		private helperService: HelperService,
		private activeModal: BsModalRef,
	) { }

	ngOnInit(): void {
		this.ratings = this.helperService.generateNumberList(0, 10);
		this.userSurvey.bestEmail = this.user.email;
	}

	get parentBrokerage(): string {
		return this.user.agent?.parent ? this.user.agent?.parent.displayName : 'Power Kiosk';
	}

	get selectedScore(): boolean {
		return !!this.userSurvey.recommendScore || this.userSurvey.recommendScore === 0;
	}

	public closeBestEmail(): void {
		if (this.userSurvey.canContact === true) {
			this.closingBestEmail = true;
			setTimeout(() => {
				this.closingBestEmail = false;
			}, 600);
		}
	}

	public closeForm(): void {
		this.closingForm = true;
		setTimeout(() => {
			this.closingForm = false;
			this.closedForm = true;
		}, 600);
	}

	public async submit(): Promise<void> {
		this.submitDisabled = true;
		this.submitText = 'Submitting...';
		this.userSurvey.isComplete = true;
		await this.updateuserSurvey();
		this.closeForm();
	}

	public async updateuserSurvey(): Promise<void> {
		try {
			if (!this.userSurvey.canContact) {
				this.userSurvey.bestEmail = null;
			}
			if (!this.userSurvey.bestEmail) {
				this.userSurvey.canContact = false;
			}
			await this.graphqlService.updateUserSurvey(this.userSurvey.id, _.omit(this.userSurvey, 'id'));
		} catch (e) {
			// for now we don't show anything
		}
	}

	public hide(): void {
		this.userSurvey.closedSurveyWithButton = true;
		this.userSurvey.isComplete = true;
		this.activeModal.hide();
	}
}
