import { environmentShared } from './environment.shared';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	name: 'support',
	demo: false,
	baseUrl: 'https://apisupport.powerkiosk.com',
	baseAPIUrl: 'https://apisupport.powerkiosk.com',
	graphQLEndpoint: 'https://graphqlsupport.powerkiosk.com/graphql',
	apiKey: '',
	stripeKey: 'pk_test_7YXS6tuhvcq8NkQjV92IA8kY',
	...environmentShared,
};
