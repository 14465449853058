

export class RateMatrixLoader {

	public id: number;
	public assemblyName: string;
	public description: string;
	public isAPI: boolean;
	public showUrl: boolean;
	public showUsername: boolean;
	public showPassword: boolean;
	public showClientId: boolean;
	public showClientSecret: boolean;
	public showAccessKeyId: boolean;
	public showToken: boolean;
	public showPromoCode: boolean;
	public showAgentId: boolean;
	public showSystemId: boolean;
	public showInterfaceId: boolean;
	public showPaymentUrl: boolean;
	public showRateUrl: boolean;
	public showAccessTokenUrl: boolean;
	public showBrokerCode: boolean;
	public showEncryptionKey: boolean;
	public showEncryptionIV: boolean;

	constructor(rateMatrixLoader?: RateMatrixLoader) {
		Object.assign(this, rateMatrixLoader);
	}
}
